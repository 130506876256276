// import logo from './logo.svg';
import './App.css';
// import Welcome from './component/Welcome';
import UnderMaintenance from './component/UnderMaintenance';

function App() {
  return (
    <div className="App">
      <UnderMaintenance></UnderMaintenance>
      {/* <Welcome></Welcome> */}
    </div>
  );
}

export default App;
