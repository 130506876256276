import React from 'react';
import { FaFacebook, FaTiktok, FaInstagram, FaEnvelope } from 'react-icons/fa';
import './css/UnderMaintenance.css'; // Custom CSS for styling

const UnderMaintenance = () => {
  return (
    <div className="maintenance-container">
      <div className="maintenance-content">
        
        {/* Company Logo */}
        <div className="company-logo">
          <img src="Kontriman_logo.png"  alt="Kontriman Cooperative Credit Union" className="logo" />
        </div>
        
        <h1>We'll be back soon!</h1>
        <p>
          Our website is currently undergoing scheduled maintenance to serve you better.
          We’ll be back shortly. Thank you for your patience!
        </p>
        
        <div className="contact-info">
          <FaEnvelope /> <span>Contact us at <a href="mailto:support@kontrigroup.com">support@kontrigroup.com</a></span>
        </div>
        
        <div className="social-icons">
          <a href="https://www.facebook.com/kontrimanofficial" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <FaFacebook />
          </a>
          <a href="https://www.tiktok.com/@kontrimanofficial" target="_blank" rel="noopener noreferrer" aria-label="TikTok">
            <FaTiktok />
          </a>
          <a href="https://www.instagram.com/kontrimanofficial/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <FaInstagram />
          </a>
        </div>
      </div>
    </div>
  );
};

export default UnderMaintenance;
